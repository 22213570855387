import React, { useContext, useState } from 'react'
import { Box, Container, Tab, Tabs } from '@material-ui/core';
import { txt } from '../../common/context';
import { PrimaryButton } from '../../components/CustomMUI/CustomButtons';
import { AuthContext } from '../../context/AuthContext';
import PlayerProfileInfoCard from '../../components/Player/PlayerProfileInfoCard';
import { TabContext, TabPanel } from '@material-ui/lab';
import { ChevronRight } from '@material-ui/icons';
import PersistentDrawerRight from '../../components/navBar/nav';
import PersonalDetails from './PersonalDetails';
import PlayerStatsModal from '../../components/Player/PlayerStats/PlayerStatsModal';

function Profile() {
	const [selectedTab, setSelectedTab] = useState(txt.Personal_Details);
	const { userInfo } = useContext(AuthContext)
	const [showPlayerStats, setIsShowPlayerStats] = useState(false);

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	return (
		<div>
			<PersistentDrawerRight title={txt.My_Profile} />
			<Container>
				<PlayerProfileInfoCard userInfo={userInfo} />
				<Box my={2} className='d-flex'>
					<PrimaryButton endIcon={<ChevronRight />} size="small" disabled>Go Pro</PrimaryButton>
					<PrimaryButton
						onClick={() => setIsShowPlayerStats(true)}
						endIcon={<ChevronRight />} size="small"
					>
						My Stats
					</PrimaryButton>
				</Box>
			</Container>
			<TabContext value={selectedTab}>
				<Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)} textColor="primary" indicatorColor="primary">
					<Tab label={txt.Personal_Details} value={txt.Personal_Details} {...a11yProps(0)} />
					<Tab label={txt.Preferences} value={txt.Preferences} {...a11yProps(1)} disabled />
					<Tab label={txt.Payments} value={txt.Payments} {...a11yProps(2)} disabled />
					<Tab label={txt.Connections} value={txt.Connections} {...a11yProps(3)} disabled />
				</Tabs>
				<TabPanel value={txt.Personal_Details} style={{ padding: '1rem', backgroundColor: 'var(--card-1-bg)' }}>
					<PersonalDetails />
				</TabPanel>
				<TabPanel value={txt.Preferences} style={{ padding: '1rem' }}>
				</TabPanel>
				<TabPanel value={txt.Payments} style={{ padding: '1rem' }}>
				</TabPanel>
				<TabPanel value={txt.Connections} style={{ padding: '1rem' }}>
				</TabPanel>
			</TabContext>
			<PlayerStatsModal show={showPlayerStats} closeModal={() => setIsShowPlayerStats(false)} userInfo={userInfo} />
		</div>
	)
}

export default Profile