export const Genders = ["Male", "Female", "Prefer Not To Say"]

export const playingRoles = [
    "Top-Order Batter",
    "Middle-Order Batter",
    "Wicket-Keeper Batter",
    "Wicket Keeper",
    "Bowler",
    "All-Rounder",
    "Lower-Order Batter",
    "Opening Batter",
    "None",
];

export const battingStyles = ["Left-Hand Bat", "Right-Hand Bat"]

export const bowlingStyles = [
    "Right-Arm Fast",
    "Right-Arm Medium",
    "Left-Arm Fast",
    "Left-Arm Medium",
    "Slow Left-Arm Orthodox",
    "Slow Left-Arm Chinaman",
    "Right-Arm Off Break",
    "Right-Arm Leg break",
    "None",
]

export const ballTypes = ["Tennis Ball", "Red Ball", "White Ball", "Pink Ball"]

export const pitchTypes = ["Indoor", "Matting", "Astra", "Turf", "Grass"]

export const matchTypes = ["Limited overs", "Indoor", "Test match"]

export const tournamentTypes = ["League", "Knockout", "Group", "Round robin"]

export const liveStreamingOptions = ["Yes", "No", "Maybe"]