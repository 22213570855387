import React, { useEffect, useState } from 'react'
import Header from '../CustomMUI/Header'
import { CircularProgress, Tab, Tabs } from '@material-ui/core'
import { useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BASE_URL, sendHttpRequest } from '../../common/Common';

function ScoreCard() {
    const { matchId } = useParams();
    const [tabIndex, setTabIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [matchData, setMatchData] = useState();
    const [matchDataforCard, setMatchDataforCard] = useState();

    // const location = useLocation();
    // const state = location.state;
    // console.log('matchId : ', state?.matchData)

    useEffect(() => {
        getMatchForCard();
        getMatchFullDetails();
    }, [])

    useEffect(() => {
        console.log("matchDataforCard : ", matchDataforCard)
    }, [matchDataforCard])


    const getMatchForCard = async () => {
        try {
            const response = await sendHttpRequest('GET', `${BASE_URL}/api/match/id/${matchId}`);
            const data = response.data;

            if (data.data) {
                setMatchDataforCard(data.data)
            }
        } catch (error) {
            toast.error('Failed to fetch match details:', error);
        }
    }

    const getMatchFullDetails = async () => {
        try {
            const response = await sendHttpRequest('GET', `${BASE_URL}/api/match/${matchId}`);
            const data = response.data;
            if (data.status === 1 && data.data) {
                setMatchData(data.data)
                setIsLoading(false);
            }
        } catch (error) {
            toast.error('Failed to fetch match details:', error);
        }
    }

    return (
        <div style={{ minHeight: '100vh' }}>
            {
                isLoading &&
                <div style={{ background: 'rgba(0,0,0,0.3)', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress style={{ margin: 'auto' }} />
                </div>
            }

        </div>
    )
}

export default ScoreCard;