import React, { useContext } from "react";
import PlayerStatsModal from "../components/Player/PlayerStats/PlayerStatsModal";
import { AuthContext } from "../context/AuthContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const MyStats = () => {
  const history = useHistory();
  const { userInfo } = useContext(AuthContext);

  return (
    <PlayerStatsModal show={true} closeModal={() => history.goBack()} userInfo={userInfo} />
  );
};

export default MyStats;