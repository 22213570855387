import React from 'react'

function Details({ teamDetails }) {

  return (
    <div>
    </div>
  )
}

export default Details