import React, { useState } from "react";
import PersistentDrawerRight from "../../../components/navBar/nav";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SpeedDial, SpeedDialIcon, TabContext, TabPanel } from "@material-ui/lab";
import { Tab, Tabs } from "@material-ui/core";
import MyTeams from "./MyTeams";
import OtherTeams from "./OtherTeams";

function Teams() {
  const history = useHistory();
  const [tabValue, setTabValue] = useState('my');

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <div>
      <PersistentDrawerRight title="Teams" />
      <TabContext value={tabValue}>
        <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} centered textColor="primary" indicatorColor="primary" >
          <Tab label="My teams" value="my" {...a11yProps(0)} />
          <Tab label="Other teams" value="other" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value="my" style={{ padding: '1rem' }}><MyTeams /></TabPanel>
        <TabPanel value="other" style={{ padding: '1rem' }}><OtherTeams /></TabPanel>
      </TabContext>
      <SpeedDial
        open={false}
        ariaLabel="New team"
        onClick={() => history.push('/team/create')}
        style={{ position: 'absolute', bottom: 60, right: 10 }}
        icon={<SpeedDialIcon />}
      />
    </div>
  );
}

export default Teams;
