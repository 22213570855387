import React, { useState, useEffect, useRef } from "react";
import { PrimaryButton } from "../../../components/CustomMUI/CustomButtons";
import lock from "../../../assets/images/svg/lock.svg";
import { CircularProgress, Typography, Container } from "@material-ui/core";
import CustomMobileInput from "../../../components/CustomPhoneInput/CustomPhoneInput";
import CustomOtpInput from "../../../components/CustomMUI/CustomOtpInput";
import { sendHttpRequest, BASE_URL } from "../../../common/Common";
import { txt } from "../../../common/context";
import { auth, RecaptchaVerifier, signInWithPhoneNumber } from "../../../config/firebase";
import { toast } from "react-toastify";
import Header from "../../../components/CustomMUI/Header";

const OtpVerify = ({ formdata, handleRegister, goBack }) => {
  const [otp, setOtp] = useState("");
  const [isSendingOTP, setIsSendingOTP] = useState(false);
  const [isValidatingOTP, setIsValidatingOTP] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [timer, setTimer] = useState(0);
  const recaptchaVerifierRef = useRef(null);
  let interval = useRef(null);

  useEffect(() => {
    handleSendOtp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formdata.contactNo]);

  useEffect(() => {
    if (isOtpSent && timer > 0) {
      interval.current = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval.current);
    }
    return () => clearInterval(interval.current);
  }, [isOtpSent, timer]);

  const initializeRecaptcha = () => {
    if (!recaptchaVerifierRef.current) {
      recaptchaVerifierRef.current = new RecaptchaVerifier("recaptcha-container", {
        size: "invisible",
        callback: (response) => {
          console.log("reCAPTCHA solved");
        },
        "expired-callback": () => {
          console.error("reCAPTCHA expired");
        },
      }, auth);
      recaptchaVerifierRef.current.render();
    }
  };

  const handleSendOtp = () => {
    setIsSendingOTP(true);
    setIsOtpSent(false);

    if (formdata.countryCode === '94') {
      sendHttpRequest("POST", BASE_URL + "/api/auth/send-otp", null, JSON.stringify({ contactNo: formdata.contactNo })).then((res) => {
        setIsOtpSent(true);
        setTimer(60);
      }).catch((error) => {
        toast.error(error.response.data.error);
        console.log(error.response);
      }).finally(() => {
        setIsSendingOTP(false);
      });
    } else {
      initializeRecaptcha();
      signInWithPhoneNumber(auth, `+${formdata.contactNo}`, recaptchaVerifierRef.current).then((confirmationResult) => {
        setConfirmationResult(confirmationResult);
        setIsOtpSent(true);
        setTimer(60);
      }).catch((error) => {
        console.log(error);
        toast.error(error.message);
      }).finally(() => {
        setIsSendingOTP(false);
      })
    }
  };

  const handleVerify = () => {
    setIsValidatingOTP(true);

    if (formdata.countryCode === '94') {
      const data = {
        contactNo: formdata.contactNo,
        otp: otp,
      }

      sendHttpRequest("POST", BASE_URL + "/api/auth/verify-otp", null, JSON.stringify(data)).then((confirmation) => {
        console.log(confirmation);
        if (confirmation.success) {
          handleRegister();
        } else {
          toast.error(txt.invalid_otp);
        }
      }).catch((error) => {
        toast.error(txt.invalid_otp);
        console.error(error.response);
      }).finally(() => {
        setIsValidatingOTP(false);
      });
    } else {
      confirmationResult.confirm(otp).then(() => {
        handleRegister();
      }).catch((error) => {
        toast.error(txt.invalid_otp);
        console.error(error);
      }).finally(() => {
        setIsValidatingOTP(false);
      });
    }
  };

  return (
    <Container>
      <Header isModal={true} closeModal={() => goBack()} title={txt.number_verification} />
      <img alt="lock" src={lock} width="100px" className="mb-15" />
      <Typography variant="h5">{txt.number_verification}</Typography>
      <CustomMobileInput
        label={txt.phone_number}
        countryCode={formdata.countryCode} phone={formdata.contactNo}
        disabled={true}
      />
      <Typography variant="body2" className="my-15" gutterBottom>{txt.enter_otp}</Typography>
      <CustomOtpInput
        numInputs={6}
        onChange={(value) => setOtp(value)}
      />
      <Typography variant="body2" gutterBottom className="my-15"
        onClick={timer === 0 ? handleSendOtp : null}
        style={{
          color: isOtpSent ? timer > 0 ? "var(--status-info)" : "var(--status-info)" : "gray",
          cursor: timer === 0 ? "pointer" : "default",
        }}
      >
        {isOtpSent ? timer > 0 ? `${txt.did_not_receive_code} in ${timer}s` : txt.did_not_receive_code : txt.did_not_receive_code}
      </Typography>
      <div id="recaptcha-container"></div>
      <PrimaryButton
        onClick={() => handleVerify()}
        disabled={isValidatingOTP || isSendingOTP}
        endIcon={(isValidatingOTP || isSendingOTP) && <CircularProgress color='inherit' size={'1.5rem'} />}
      >
        {isSendingOTP ? "Sending OTP" : txt.register}
      </PrimaryButton>
    </Container>
  );
};

export default OtpVerify;