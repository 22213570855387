import React, { useState } from "react";
import RedBall from "../../assets/images/score/Red_Ball.svg";
import { txt } from "../../common/context";
import { FormControlLabel, makeStyles, Radio, RadioGroup } from "@material-ui/core";
import clsx from "clsx";
import { PrimaryButton } from "../CustomMUI/CustomButtons";
import BottomDrawer from "../BottomDrawer";

const CustomWicketSelectBottomSheet = ({ isOpen, onDismiss, wicketTypes, handleWicket }) => {
  const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: '50%',
      width: 40,
      height: 40,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: '#137cbd',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 40,
        height: 40,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#106ba3',
      },
    },
  });

  const classes = useStyles();

  const [selectedWicketType, setSelectedWicketType] = useState("");

  const handleClose = () => {
    onDismiss();
    setSelectedWicketType("");
  }

  return (
    <BottomDrawer
      isOpen={isOpen} onDismiss={() => handleClose()}
      providedIcon={RedBall} title={txt.wicket} description={txt.select_the_dismissal_type}
    >
      <RadioGroup
        name={txt.wicket}
        value={selectedWicketType} onChange={(e) => setSelectedWicketType(e.target.value)}
      >
        {wicketTypes.map((option, index) => (
          <FormControlLabel
            key={index} value={option}
            label={<span style={{ fontSize: "20px", fontWeight: '600' }}>{option}</span>}
            control={
              <Radio
                disableRipple color="default"
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                icon={<span className={classes.icon} />}
              />
            }
          />
        ))}
      </RadioGroup>
      <PrimaryButton onClick={() => { handleWicket(selectedWicketType); handleClose() }}>
        {txt.continue}
      </PrimaryButton>
    </BottomDrawer>
  );
};

export default CustomWicketSelectBottomSheet;