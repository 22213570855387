import React, { useState } from "react";
import styles from "./style.module.css";

const ScoreCardPlay = () => {
  const [clickedButton, setClickedButton] = useState(null);

  const handleButtonClick = (buttonName) => {
    setClickedButton(buttonName);
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.buttons}>
        <button
          className={`${styles.button} ${clickedButton === 'My Team' ? styles.clickedButton : ''}`}
          onClick={() => handleButtonClick('My Team')}
        >
          My Team
        </button>
        <button
          className={`${styles.button} ${clickedButton === 'RAC Excellence' ? styles.clickedButton : ''}`}
          onClick={() => handleButtonClick('RAC Excellence')}
        >
          RAC Excellence
        </button>
        <button
          className={`${styles.button} ${clickedButton === 'Match Flow' ? styles.clickedButton : ''}`}
          onClick={() => handleButtonClick('Match Flow')}
        >
          Match Flow
        </button>
        <button
          className={`${styles.button} ${clickedButton === 'Info' ? styles.clickedButton : ''}`}
          onClick={() => handleButtonClick('Info')}
        >
          Info
        </button>
      </div>
      <div className={styles.battingContainer}>
        <div className={`${styles.battingSection} ${styles.section}`}>
          <div className={styles.teamHeader}>
            Team Name <span className={styles.teamOvers}>(20 ovs maximum)</span>
          </div>

          <div className={styles.gap}></div> 

          <div className={styles.headerBar}>
            <span className={styles.headerItem}>Batting</span>
            <span className={styles.headerItems}>R</span>
            <span className={styles.headerItems}>B</span>
            <span className={styles.headerItems}>0s</span>
            <span className={styles.headerItems}>4s</span>
            <span className={styles.headerItems}>6s</span>
            <span className={styles.headerItems}>SR</span>
          </div>

          <table className={styles.scoreTable}>
            <tbody>
              {[...Array(5)].map((_, index) => (
                <tr key={index}>
                  <td className={styles.tableCell}>
                    Player Name {index % 2 === 0 ? <span className={styles.playerDetails}>c Fielder Name b Bowler Name</span> : <span className={styles.playerDetails}>Not Out</span>}
                  </td>
                  <td className={`${styles.tableCell} ${styles.boldText}`}>20</td>
                  <td className={styles.tableCell}>20</td>
                  <td className={styles.tableCell}>20</td>
                  <td className={styles.tableCell}>20</td>
                  <td className={styles.tableCell}>20</td>
                  <td className={styles.tableCell}>100.0</td>
                </tr>
              ))}
              <tr>
                <td className={styles.tableCell}>
                  <span className={styles.extrasLabel}>Extras</span>
                </td>
                <td className={`${styles.tableCell} ${styles.boldText}`}>20</td>
                <td className={styles.tableCell} colSpan={5}>
                  <span className={styles.smallText}>( b: 00, lb: 00, w: 00, nb: 00 )</span>
                </td>
              </tr>
            </tbody>
          </table>

          <div className={styles.total}>
            <span>Total</span>
            <span>100 <span className={styles.totalOvers}>19.5 ov (RR: 6.60)</span></span>
          </div>

          <div className={styles.didNotBat}>
            <strong>Did not bat:</strong> Player Name, Player Name, Player Name, Player Name, Player Name, Player Name
          </div>
          <div className={styles.fallOfWickets}>
            <strong>Fall of wickets:</strong> 1-41 (Player Name, 3.3 ov), 1-41 (Player Name, 3.3 ov), 1-41 (Player Name, 3.3 ov), 1-41 (Player Name, 3.3 ov), 1-41 (Player Name, 3.3 ov)
          </div>
        </div>
      </div>

      <div className={styles.gap}></div> 

      <div className={styles.bowlingContainer}>
        <div className={`${styles.bowlingSection} ${styles.section}`}>
          <BowlingComponent />
        </div>
      </div>
    </div>
  );
};

const BowlingComponent = () => {
  return (
    <div>
      <div className={styles.headerBar}>
        <span className={styles.headerItem}>Bowling</span>
        <span className={styles.headerBowling}>O</span>
        <span className={styles.headerBowling}>M</span>
        <span className={styles.headerBowling}>R</span>
        <span className={styles.headerBowling}>W</span>
        <span className={styles.headerBowling}>ECON</span>
        <span className={styles.headerBowling}>WD</span>
        <span className={styles.headerBowling}>NB</span>
      </div>
      
      <table className={styles.scoreTable}>
        <tbody>
          {[...Array(6)].map((_, index) => (
            <tr key={index}>
              <td className={styles.tableCell}>Player Name</td>
              <td className={styles.tableCell}>10</td>
              <td className={styles.tableCell}>10</td>
              <td className={styles.tableCell}>20</td>
              <td className={`${styles.tableCell} ${styles.boldText}`}>10</td>
              <td className={styles.tableCell}>13.00</td>
              <td className={styles.tableCell}>10</td>
              <td className={styles.tableCell}>10</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ScoreCardPlay;