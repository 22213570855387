import React, { useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import { FaFacebook, FaWhatsapp } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import styles from "./style.module.css";
import CloseIcon from "../../assets/images/svg/close.svg";

const CustomButtons = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.socialShare}>
      <button className={styles.shareMainButton} onClick={() => setIsOpen(true)}>
        Share Scorecard
      </button>

      <BottomSheet open={isOpen} onDismiss={() => setIsOpen(false)}>
        <div className={styles.bottomSheetContainer}>
          <div className={styles.header}>
            <h3 className={styles.shareTitle}>Share This Scorecard</h3>
            <img
              src={CloseIcon}
              alt="Close"
              className={styles.closeIcon}
              onClick={() => setIsOpen(false)}
            />
          </div>
          <div className={styles.shareButtonsContainer}>
            <button
              className={`${styles.shareButton} ${styles.facebook}`}
              onClick={() =>
                window.open(
                  "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href,
                  "_blank"
                )
              }
            >
              <FaFacebook className={styles.icon} /> Facebook
            </button>
            <button
              className={`${styles.shareButton} ${styles.twitter}`}
              onClick={() =>
                window.open(
                  "https://twitter.com/intent/tweet?url=" + window.location.href,
                  "_blank"
                )
              }
            >
              <FaXTwitter className={styles.icon} /> X
            </button>
            <button
              className={`${styles.shareButton} ${styles.whatsapp}`}
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send?text=" + window.location.href,
                  "_blank"
                )
              }
            >
              <FaWhatsapp className={styles.icon} /> WhatsApp
            </button>
          </div>
        </div>
      </BottomSheet>
    </div>
  );
};

export default CustomButtons;
