import React, { useContext } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Register from "./pages/Auth/Registration/Register";
import Scoresheet from "./components/score/Scoresheet";
import Pool from "./components/pool/Pool";
import Pools from "./components/pool/Pools";
import Results from "./components/sessions/Results";
import AccessDenied from "./components/error/AccessDenied";
import Tournaments from "./pages/Tournament/Tournaments/Tournaments";
import Tournament from "./pages/Tournament/Tournament/Tournament";
import TournamentSettings from "./components/tournaments/TournamentSettings";
import "./app.css";
import GettingStartedOne from "./components/GettingStarted/GettingStartedOne";
import CreatePlayer from "./components/Player/CreatePlayer";
import MyStats from "./pages/MyStats";
import More from "./components/More/More";
import CreateTournament from "./pages/Tournament/CreateTournament/CreateTournament";
import RegisterTeamTournament from "./pages/Tournament/RegisterTeamTournament";
import ScoreCard from './components/ScoreSheet/ScoreCard';
import Profile from './pages/Profile/Profile';
import EditProfile from './pages/Profile/EditProfile';
import { AuthContext } from './context/AuthContext';
import Layout from './Layout';
import CreateTeam from './pages/Team/CreateTeam';
import CreateMatch from './pages/Match/CreateMatch/CreateMatch';
import HomeScreen from './pages/HomeScreen';
import Matches from './pages/Match/Matches';
import Match from './pages/Match/Match/Match';
import MatchToss from './pages/Match/Match/MatchToss';
import Players from './pages/Players/Players';
import ScoreSheet from './components/ScoreSheet/ScoreSheet';
import Login from './pages/Auth/Login';
import ForgotPassword from './pages/Auth/ForgotPW/ForgotPassword';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';
import Teams from './pages/Team/Teams/Teams';
import Team from './pages/Team/Team/Team';

function App() {
  const { authenticated } = useContext(AuthContext);

  return (
    <BrowserRouter>
      <ToastContainer draggable={false} autoClose={5000} position={toast.POSITION.TOP_CENTER} />
      <Switch>
        {authenticated ? (
          <Layout>
            <Route exact path="/"><Redirect to="/home" /></Route>
            <Route exact path="/home" component={HomeScreen} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/profile/edit" component={EditProfile} />
            <Route exact path="/pools" component={Pools} />
            <Route exact path="/my-stats" component={MyStats} />
            <Route exact path="/scorecard/:matchId" component={ScoreCard} />
            <Route exact path="/more" component={More} />
            <Route path="/pool/:poolId" component={Pool} />
            <Route exact path="/player/all" component={Players} />
            <Route exact path="/team/create" component={CreateTeam} />
            <Route exact path="/team/all" component={Teams} />
            <Route exact path="/team/info/:teamId" component={Team} />
            <Route exact path="/match/create" component={CreateMatch} />
            <Route exact path="/match/update/:matchId" component={CreateMatch} />
            <Route exact path="/match/all" component={Matches} />
            <Route exact path="/match/info/:matchId" component={Match} />
            <Route exact path="/match/toss" component={MatchToss} />
            <Route exact path="/match/score-sheet" component={ScoreSheet} />
            <Route exact path="/tournament/create" component={CreateTournament} />
            <Route exact path="/tournament/all" component={Tournaments} />
            <Route exact path="/tournament/info/:tournamentId" component={Tournament} />
            <Route exact path="/tournament/register/:tournamentId" component={RegisterTeamTournament} />
            <Route exact path="/tournament/settings/:tournamentId" component={TournamentSettings} />
            <Route path="/series-results/:seriesId" component={Results} />
            <Route path="/scoresheet/:matchId" component={Scoresheet} />
            {/* <Route path="/match/:matchId" component={ScoreCard} /> */}
            <Route path="/createPlayer" component={CreatePlayer} />
            <Route path="/403" component={AccessDenied} />
          </Layout>
        ) : (
          <>
            <Route exact path="/" component={Login} />
            <Route exact path="/gs" component={GettingStartedOne} />
            <Route exact path="/signup" component={Register} />
            <Route exact path="/forgotPassword" component={ForgotPassword} />
          </>
        )}
      </Switch>
    </BrowserRouter>
  )
}

export default App;