import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import { useHistory } from "react-router-dom";
import { KeyboardArrowLeft } from "@material-ui/icons";
import AppLogoIcon from "../../assets/images/FieldR_Logo_Blue.png";
import MenuIcon from "../../assets/images/svg/menu.svg";
// import MessageIcon from "../../assets/images/svg/message.svg";
// import NotificationIcon from "../../assets/images/svg/notification.svg";
import { AuthContext } from "../../context/AuthContext";
import PlayerIcon from "../../assets/images/svg/player.svg";
import TeamIcon from "../../assets/images/svg/team.svg";
import MatchIcon from "../../assets/images/svg/match.svg";
import TournamentIcon from "../../assets/images/svg/tournament.svg";
import { txt } from "../../common/context";
import { PrimaryButton } from "../CustomMUI/CustomButtons";
import PlayerProfileInfoCard from "../Player/PlayerProfileInfoCard";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  headerDiv: {
    width: "100%",
    alignItems: 'center',
    display: "flex",
    justifyContent: "space-between",
  },
  logoImg: {
    height: "25px",
    marginLeft: "8px",
  },
}));

export default function PersistentDrawerRight({ title, farLeft, hideMenu }) {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { logout, userInfo } = useContext(AuthContext);


  const handleLogout = () => {
    logout();
    history.push('/');
  };

  return (
    <div style={{ display: 'flex' }}>
      <AppBar
        style={{ backgroundColor: "var(--primary-color-white)", boxShadow: 'none' }}
        position="fixed"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            style={{ display: 'flex', alignItems: 'center' }} onClick={() => setOpen(true)}
          >
            <img src={MenuIcon} alt="Menu Icon" />
          </IconButton>
          {farLeft && (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={() => history.goBack()}
            >
              <KeyboardArrowLeft />
            </IconButton>
          )}
          <div className={classes.headerDiv}>
            <img src={AppLogoIcon} alt="App Logo" className={classes.logoImg} />
            {/* Uncomment if needed */}
            {/* <div>
              <IconButton edge="end" color="inherit">
                <img src={MessageIcon} alt="Messages Icon" />
              </IconButton>
              <IconButton edge="end" color="inherit">
                <img src={NotificationIcon} alt="Notifications Icon" />
              </IconButton>
            </div> */}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={open} onClose={() => setOpen(false)} width="300px">
        <div className="px-15 py-15">
          <PlayerProfileInfoCard userInfo={userInfo} showCricketInfo={false} onClick={() => history.push("/profile")} />
          <Divider className="my-15" />
          <div className="d-flex flex-column">
            <div className="d-flex w-100 cursor-pointer" onClick={() => history.push("/player/all")}>
              <img src={PlayerIcon} alt="player icon" />
              <Typography variant="body1" style={{fontWeight: 'bold'}}>{txt.view_all_player}</Typography>
            </div>
            <div className="d-flex w-100 cursor-pointer" onClick={() => history.push('/team/all')}>
              <img src={TeamIcon} alt="team icon" />
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>{txt.view_all_teams}</Typography>
            </div>
            <div className="d-flex w-100 cursor-pointer" onClick={() => history.push('/match/all')}>
              <img src={MatchIcon} alt="match icon" />
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>{txt.view_all_matches}</Typography>
            </div>
            <div className="d-flex w-100 cursor-pointer" onClick={() => history.push('/tournament/all')}>
              <img src={TournamentIcon} alt="tournament icon" />
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>{txt.view_all_tournaments}</Typography>
            </div>
          </div>
          <Divider className="my-15" />
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div className="d-flex w-100 cursor-pointer" onClick={() => history.push("/403")}>
              <img src={PlayerIcon} alt="player icon" />
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>{txt.Leaderboards}</Typography>
            </div>
            <div className="d-flex w-100 cursor-pointer" onClick={() => history.push('/more')}>
              <img src={TeamIcon} alt="team icon" />
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>{txt.Payments}</Typography>
            </div>
            <div className="d-flex w-100 cursor-pointer" onClick={() => history.push('/403')}>
              <img src={MatchIcon} alt="match icon" />
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>{txt.ShareTheApp}</Typography>
            </div>
            <div className="d-flex w-100 cursor-pointer" onClick={() => history.push('/403')}>
              <img src={TournamentIcon} alt="tournament icon" />
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>{txt.Settings}</Typography>
            </div>
          </div>
          <Divider className="my-15" />
          <PrimaryButton onClick={() => handleLogout()}>{txt.Logout}</PrimaryButton>
        </div>
      </Drawer>
    </div>
  );
}
