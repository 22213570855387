import { Box, Typography } from '@material-ui/core'
import React from 'react'

function StatsCard({ number, title, backgroundColor }) {
	return (
		<Box
			p={1} width="100%" height="100%" textAlign="center"
			style={{ background: backgroundColor === 'blue' ? '#9AC7DF40' : '#CAFFBF59', borderRadius: '15px', boxShadow: '0px 2px 6px 0px #00000040' }}
		>
			<Typography variant='h5' gutterBottom>{number}</Typography>
			<Typography variant='body2'>{title}</Typography>
		</Box>
	)
}

export default StatsCard