import React from 'react'
import CustomButtons from '../../../components/ShareToSocial/CustomShareButtons'
import ScoreCardPlay from '../../../components/ScoreSheet/ScoreCardPlay'

function Analysis() {
  return (
    <div>
      <CustomButtons />
      <ScoreCardPlay />
    </div>
  )
}

export default Analysis