import React, { useContext } from "react";
import { Grid, Box, IconButton } from "@material-ui/core";
import { CustomCardButton } from "../CustomMUI/CustomSmallButton";
import MinusIcon from "../../assets/images/svg/MinusIcon.svg";
import { AuthContext } from "../../context/AuthContext";
import { SmallBlueButton } from "../CustomMUI/CustomButtons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TeamCard = ({ team, buttonName, onClick, isRemove }) => {
  const { userInfo } = useContext(AuthContext);
  const history = useHistory();

  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs onClick={() => history.push(`/team/info/${team._id}`)}>
          <Box>
            <div style={{ color: "#000", fontFamily: "DM Sans", fontWeight: 400, fontSize: "15px" }}>
              {team.name}
            </div>
            <div style={{ color: "var(--color1)", fontFamily: "DM Sans", fontWeight: 400, fontSize: "13px" }}>
              {team.teamLocation}
            </div>
          </Box>
        </Grid>
        {team.owner._id === userInfo._id && (
          <Grid item onClick={() => history.push(`/tea/info/${team._id}`)}>
            <SmallBlueButton disabled>Admin</SmallBlueButton>
          </Grid>
        )}
        <Grid item>
          {isRemove ? (
            <IconButton onClick={() => onClick(team)}>
              <img src={MinusIcon} alt="remove" />
            </IconButton>
          ) : onClick ? (
            <CustomCardButton
              onClick={() => onClick(team)}
              disabled={false}
              name={buttonName}
            />
          ) : (
            <CustomCardButton
              onClick={() => history.push(`/team/info/${team._id}`)}
              disabled={false}
              name='View'
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default TeamCard;