import React, { useEffect } from 'react'
import PlayerProfileInfoCard from '../PlayerProfileInfoCard';
import { useState } from 'react';
import { txt } from '../../../common/context';
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, Tab, Tabs } from '@material-ui/core';
import { BASE_URL, sendHttpRequest } from '../../../common/Common';
import StatsCard from './StatsCard';
import MatchCard from '../../Match/MatchCard';
import { toast } from 'react-toastify';
import { KeyboardArrowLeft } from '@material-ui/icons';
import { TabContext, TabPanel } from '@material-ui/lab';
import Stats from './Stats';

function PlayerStatsModal({ show, userInfo, closeModal }) {
	const [isLoading, setIsLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState('Statistics');
	const [totalRuns, setTotalRuns] = useState(0);
	const [playerMatches, setPlayerMatches] = useState([]);

	useEffect(() => {
		getAllMatches();
		getTotalRuns();
		// getTotalWickets();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userInfo._id])

	const getTotalRuns = () => {
		setIsLoading(true);
		sendHttpRequest("GET", `${BASE_URL}/api/player/totalRuns/${userInfo._id}`).then((res) => {
			setTotalRuns(res.data.data.totalRuns)
		}).catch((error) => {
			console.log(error.response)
			toast.error(error.response.data.message);
		}).finally(() => {
			setIsLoading(false);
		});
	}

	const getAllMatches = () => {
		sendHttpRequest("GET", `${BASE_URL}/api/match/user/${userInfo._id}?isUpcomingOngoing=false`).then((res) => {
			setPlayerMatches(res.data.matches);
		}).catch((error) => {
			console.log(error.response)
			toast.error(error.response.data.message);
		}).finally(() => {
			setIsLoading(false);
		});
	}

	// const getTotalWickets = () => {
	// 	sendHttpRequest("GET", `${BASE_URL}/api/player/totalWickets/${userInfo._id}`).then((res) => {
	// 		setPlayerMatches(res.data.data);
	// 	}).catch((error) => {
	// 		console.log(error.response)
	// 		toast.error(error.response.data.message);
	// 	}).finally(() => {
	// 		setIsLoading(false);
	// 	});
	// }

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	return (
		<Dialog open={show} onClose={closeModal} fullWidth fullScreen>
			<DialogTitle style={{ alignItems: 'center' }}>
				<div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
					<IconButton onClick={closeModal}>
						<KeyboardArrowLeft />
					</IconButton>
					Player Stats
				</div>
			</DialogTitle>
			<DialogContent>
				<PlayerProfileInfoCard userInfo={userInfo} showCricketInfo />
				{isLoading ? (
					<LinearProgress />
				) : (
					<>
						<Grid container spacing={2} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
							<Grid item xs={4}>
								<StatsCard number={playerMatches.length} title={txt.Matches} />
							</Grid>
							<Grid item xs={4}>
								<StatsCard number={totalRuns} title={txt.Runs} />
							</Grid>
							<Grid item xs={4}>
								<StatsCard number={'00'} title={txt.Wickets} />
							</Grid>
						</Grid>
						<TabContext value={selectedTab}>
							<Tabs
								value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)}
								centered textColor="primary" indicatorColor="primary"
							>
								<Tab label={txt.Matches} value={txt.Matches} {...a11yProps(0)} />
								<Tab label={txt.Statistics} value={txt.Statistics} {...a11yProps(1)} />
								<Tab label={txt.Teams} value={txt.Teams} {...a11yProps(2)} disabled />
							</Tabs>
							<TabPanel value={txt.Matches} style={{ padding: 0 }}>
								<Grid container spacing={2} style={{ marginTop: '1rem' }}>
									{playerMatches.map((match, index) => (
										<Grid item xs={12} sm={6} md={4} lg={3} key={index}>
											<MatchCard match={match} />
										</Grid>
									))}
								</Grid>
							</TabPanel>
							<TabPanel value={txt.Statistics} style={{ paddingLeft: 0, paddingRight: 0 }}>
								<Stats userInfo={userInfo} />
							</TabPanel>
							<TabPanel value={txt.Teams} style={{ padding: 0 }}>
							</TabPanel>
						</TabContext>
					</>
				)}
			</DialogContent>
		</Dialog>
	)
}

export default PlayerStatsModal