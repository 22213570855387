import React, { useContext, useState } from "react";
import { Container, Box } from "@material-ui/core";
import "react-phone-input-2/lib/style.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import OtpVerify from "./OtpVerify";
import KYC from "./KYC";
import { BASE_URL, sendHttpRequest } from "../../../common/Common";
import { AuthContext } from "../../../context/AuthContext";
import { toast } from "react-toastify";

function Register() {
  const { login } = useContext(AuthContext);
  const history = useHistory();
  const [formdata, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    countryCode: "94",
    contactNo: "94",
    password: "",
    confirmPassword: "",
    isLoginSuccess: false,
    contactNoError: "",
    passwordError: "",
    passMatchError: "",
  });
  const [step, setStep] = useState(1);

  const handleFormDataChange = (key, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [key]: value,
    }));
  };

  const handleRegister = () => {
    const data = {
      firstName: formdata.firstName,
      lastName: formdata.lastName,
      email: formdata.email,
      contactNo: formdata.contactNo,
      country: formdata.countryCode,
      password: formdata.password,
    };

    sendHttpRequest("POST", BASE_URL + "/api/auth/register", null, JSON.stringify(data)).then((res) => {
      localStorage.setItem('newRegister', 'true')
      login(res.data.data.token, res.data.data._id);
      history.push("/home");
    }).catch((error) => {
      toast.error(error.response.data.message);
      console.log(error.response);
    });
  };

  return (
    <Box py={2} className="flex-center" style={{ minHeight: '100vh' }}>
      <Container>
        {step === 1 ? (
          <KYC formdata={formdata} setFormData={handleFormDataChange} goToNextStep={() => setStep(2)} />
        ) : (
          <OtpVerify formdata={formdata} handleRegister={handleRegister} goBack={() => setStep(1)} />
        )}
      </Container>
    </Box>
  );
}

export default Register;