import { Card, CardActionArea, CardContent, Typography, Box, Grid } from '@material-ui/core'
import React, { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function MatchCard({ match }) {
  const location = useLocation();
  const { userInfo } = useContext(AuthContext);
  const history = useHistory();

  return (
    <Card
      elevation={0}
      style={{ border: '1px solid var(--primary-color-400)', borderRadius: '8px', boxShadow: '0px 4px 4px 0px #EDE8EC14' }}
    >
      <CardActionArea
        disabled={location.pathname.startsWith('/match/info')}
        onClick={() => history.push(`/match/info/${match._id}`)}
      >
        <CardContent style={{ padding: 0 }}>
          <Box sx={{ px: 2, py: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Typography variant='h6' className='text-primary' align='left'>{match.teamA.name}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant='h6' className='text-primary' align='center'>vs</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant='h6' className='text-primary' align='right'>{match.teamB.name}</Typography>
              </Grid>
            </Grid>
          </Box>
          {match?.status !== "NOT-STARTED" &&
            <div className="teamsNameScoreContainer">
              <div>
                <p>{match?.inning1?.battingTeam?.name}</p>
                <p>
                  <span>
                    {match?.inning1?.runs?.toString().padStart(3, '0') + "/" + match?.inning1?.wickets?.toString().padStart(2, '0')}
                  </span>
                </p>
              </div>
              <div className="matchCardopponetTeamScoreCon">
                <p>{match?.inning2?.battingTeam?.name}</p>
                <p>
                  {`Target : ${(match?.inning1?.runs + 1)?.toString().padStart(3, '0')}`}
                  &nbsp;&nbsp;
                  <span>
                    {
                      match?.inning2?.runs?.toString().padStart(3, '0') + "/" + match?.inning2?.wickets?.toString().padStart(2, '0')
                    }
                  </span>
                </p>
              </div>
              {match?.winningTeam?.name &&
                <p className="f1113400">
                  {match?.winningTeam?.name} Won by {" "}
                  {match?.winningTeam?._id === match?.inning1?.battingTeam?._id ? ((match?.inning1?.runs + 1) - match?.inning2?.runs) : match?.inning2?.runs}
                  {" "}Runs
                </p>
              }
            </div>
          }
          <Box style={{ background: 'var(--neutral-white)' }} sx={{ px: 2, py: 1 }}>
            <Grid container spacing={1}>
              {match.createdBy === userInfo._id && (
                <Grid item xs={12}>
                  <Typography variant='body1' align='center' className='text-info' gutterBottom>Hosting</Typography>
                </Grid>
              )}
            </Grid>
          </Box>
          <Box style={{ background: 'var(--neutral-white)' }} sx={{ px: 2, py: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant='body2' gutterBottom>{match.matchDate}</Typography>
                <Typography variant='body2' gutterBottom>{match.matchType}</Typography>
                <Typography variant='body2' gutterBottom>{match.ground}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='body2' gutterBottom align='right'>{match.pitchType}</Typography>
                <Typography variant='body2' gutterBottom align='right'>{match.overs} overs</Typography>
                <Typography variant='body2' gutterBottom align='right'>{match.ballType}</Typography>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default MatchCard