import { Box } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import MatchCard from '../../../components/Match/MatchCard';
import Styles from './SelectedMatchCompo.module.css';
import { AuthContext } from '../../../context/AuthContext';
import { DeleteButton, PrimaryButton, SecondaryButton } from '../../../components/CustomMUI/CustomButtons';
import DeleteMatchBottomSheet from '../DeleteMatchBottomSheet';

function Details({ matchDetails }) {
  const { userInfo } = useContext(AuthContext);
  const history = useHistory();
  const [showDeleteMatch, setShowDeleteMatch] = useState(false);

  return (
    <div>
      <MatchCard match={matchDetails} />
      {(matchDetails.status === 'NOT-STARTED' && matchDetails.createdBy._id === userInfo._id) ? (
        <Box my={2}>
          <Box className='flex-between' gridGap={5} mb={2}>
            <SecondaryButton onClick={() => history.push('/match/update/' + matchDetails._id)}>Edit</SecondaryButton>
            <DeleteButton onClick={() => setShowDeleteMatch(true)}>Delete</DeleteButton>
          </Box>
          <PrimaryButton onClick={() => history.push({ pathname: "/match/toss", state: { matchDetails } })}>
            Proceed for Toss
          </PrimaryButton>
        </Box>
      ) : (matchDetails.status === 'STARTED' && matchDetails.createdBy._id === userInfo._id) ? (
        <PrimaryButton className='my-15' onClick={() => history.push({ pathname: "/match/score-sheet", state: { selectedMatch: matchDetails } })}>
          Score Match
        </PrimaryButton>
      ) : null}
      <div className={Styles.container2}>
        <div>
          <p className={Styles.container2CP1}>Last 5 Match Form</p>
        </div>
        <div className={Styles.container2CD2}>
          <p className={Styles.container2CD2P1}>My Team</p>
          <div className={Styles.container2CD2CD2}>
            <div className={Styles.last5WBtns}>W</div>
            <div className={Styles.last5WBtns}>L</div>
            <div className={Styles.last5WBtns}>L</div>
            <div className={Styles.last5WBtns}>L</div>
            <div className={Styles.last5WBtns}>L</div>
          </div>
        </div>
        <div className={Styles.container2CD2}>
          <p className={Styles.container2CD2P1}>RAC Excellence</p>
          <div className={Styles.container2CD2CD2}>
            <div className={Styles.last5WBtns}>W</div>
            <div className={`${Styles.last5WBtns} ${Styles.last5WBtnsLoss}`}>L</div>
            <div className={Styles.last5WBtns}>L</div>
          </div>
        </div>
      </div>
      <div className={Styles.AdContainer}>
        <p>Advertisement Space</p>
      </div>
      <DeleteMatchBottomSheet
        isOpen={showDeleteMatch} match={matchDetails}
        onDismiss={() => setShowDeleteMatch(false)}
      />
    </div>
  )
}

export default Details