import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import CloseIcon from "../assets/images/svg/close.svg";
import RightArrow from "../assets/images/svg/rightArrow.svg";
import { Typography } from '@material-ui/core';

const BottomDrawer = ({ isOpen, onDismiss, title, providedIcon, children, description }) => {
  return (
    <BottomSheet open={isOpen} onDismiss={onDismiss} >
      <div className="container">
        <div className="flex-between mb-15">
          <img src={providedIcon || RightArrow} alt="Drawer Icon" />
          <img src={CloseIcon} alt="Close" style={{ cursor: 'pointer' }} onClick={onDismiss} />
        </div>
        <Typography variant='h6'>{title}</Typography>
        <Typography variant='subtitle2' className="mb-15">{description}</Typography>
        {children}
      </div>
    </BottomSheet>
  )
}

export default BottomDrawer