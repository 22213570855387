import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { sendHttpRequest, BASE_URL } from "../../../common/Common";
import MatchIcon from "../../../assets/images/svg/match.svg";
import Header from "../../../components/CustomMUI/Header";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import { Container } from "@material-ui/core";
import { AuthContext } from "../../../context/AuthContext";

function CreateTournament(props) {
  const { userInfo } = useContext(AuthContext);
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    startDate: "",
    endDate: "",
    ground: "",
    ballType: "",
    pitchType: "",
    tournamentType: "",
    overs: 0,
    teamCount: 0,
    registrationDeadline: "",
    currency: "LKR",
    registrationFee: 0,
    liveStreaming: "",
  });

  const handleFormDataChange = (key, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [key]: value,
    }));
  };

  function createTournament() {
    const data = {
      ...formData,
      createdBy: userInfo._id,
    }

    setIsLoading(true);
    sendHttpRequest("POST", BASE_URL + "/api/tournament/", null, JSON.stringify(data)).then((res) => {
      toast.success(res.data.message)
      props.history.push("/tournament/all")
    }).catch((error) => {
      console.log(error?.response);
      toast.error(error?.response?.data?.message)
    }).finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <div className="flex-center" style={{ height: '100%' }}>
      <Header isModal={step !== 1} closeModal={() => setStep((previousStep) => previousStep - 1)} />
      <Container>
        <img src={MatchIcon} className="round-pic" alt='icon' />
        <h1>Create Tournament</h1>
        <p>Start By Entering the details of your tournament</p>
        {step === 1 ? (
          <StepOne
            formData={formData} setFormData={handleFormDataChange}
            handleSubmit={() => setStep(2)}
          />
        ) : step === 2 ? (
          <StepTwo
            formData={formData} setFormData={handleFormDataChange}
            handleSubmit={() => setStep(3)}
          />
        ) : (
          <StepThree
            formData={formData} setFormData={handleFormDataChange}
            handleSubmit={() => createTournament()} isLoading={isLoading}
          />
        )}
      </Container>
    </div>
  );
}

export default CreateTournament;
